<template>
  <!-- 上传附件 -->
  <div class="upload">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <ul>
        <li v-for="(item, index) in photoList" :key="index">
          <p @click="toUpload(item.tit, item.type)" class="top">
            <img v-if="item.echoList.length > 0" :src="item.echoList[0]" alt="" />
            <img v-else src="./img/addimg.png" alt="" />
          </p>
          <p class="middle">
            <img :src="item.img" alt="" /><span
              >{{ item.tit }}<i  v-if="item.tit != '其他'&&item.tit != '客户照片'">(必传)</i></span
            >
          </p>
          <p class="bottom">{{ item.echoList.length }}</p>
        </li>
        <div class="submit" @click="submit">提交</div>
      </ul>
    </van-pull-refresh>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import APP from '@/utils/App'
import { routerTag } from '../../utils/index'
import API from '../../server/api'
import { Toast } from 'vant'

export default {
  setup () {
    const state = reactive({
      fileList: [],
      fileNum: 0,
      creditNo: '',
      photoList: [],
      isLoading: false
    })
    const toUpload = (name, type) => {
      const urlInfo = `everyName=${name}&type=${type}`
      const urlInfos = encodeURIComponent(urlInfo)
      APP.JUMP_TO(`${routerTag}supplementUpload?jsParam=${urlInfos}`)
    }
    // 下拉刷新
    const onRefresh = async () => {
      state.isLoading = false
      await getPhoto(state.creditNo)
    }
    // 获取图片信息
    const getPhoto = async (data) => {
      const photoList = await API.getPhoto({ creditNo: data,type:'H5'});
      if(photoList.code==200){
        state.photoList=photoList.result;
      console.log(state.photoList,'9090');
      }
    }
    // 点击提交
    const submit = async () => {
      await API.submitPhoto({ creditNo: state.creditNo })
      Toast.success('提交成功')
      setTimeout(() => {
        APP.BACK(-1)
      }, 1000)
    }
    onMounted(() => {
      state.creditNo = localStorage.getItem('creditNo')
      getPhoto(state.creditNo)
      APP.SET_TITLE('资料上传')
      APP.SET_REFRESH(true)
      if (!APP.BROWSER.android) {
        APP.PAGE_WILL_LOAD(function () {
          window.location.reload()
        })
      } else {
        APP.PAGE_WILL_LOAD(function () {
          getPhoto(state.creditNo)
        })
      }
    })
    return {
      ...toRefs(state),
      toUpload,
      submit,
      onRefresh
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
